import { request, parseErrorResponse, IRequestData } from "./axios";
import queryString from "query-string";

import { requestUpload, parseErrorResponseUpload } from "./s3-request";

const getFriends = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(req.query);
    request()
      .get(`/searches/friends?${query}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRelations = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/relationships/relationsByUserId/${req.params.userId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProfileById = (id: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/profile/${id}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getAll`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDepartmentByUserId = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/${req.params.userId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/departments/`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addMemberToDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/addMemberToDepartment`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMemberInDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/getMemberInDepartment/${req.params.departmentId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const removeMemberToDepartment = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/removeMemberToDepartment`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUploadVzonexS3AccessToken = (force = false) =>
  new Promise((resolve, reject) => {
    const cToken = localStorage.getItem("s3-auth-service");
    if (!cToken || force === true) {
      requestUpload()
        .get(`/api/auth/request-access-token`)
        .then((res) => {
          const { token } = res.data;
          if (token) {
            localStorage.setItem("s3-auth-service", token);
          }
        })
        .catch(parseErrorResponseUpload)
        .then(() => {
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });

getUploadVzonexS3AccessToken(true).catch((err) => {});

const uploadFilesVzonexS3 = (formData, isFull = false) =>
  new Promise((resolve, reject) => {
    requestUpload({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post(`/api/files`, formData)
      .then((res) => {
        const { files } = res.data;

        const data = files.map((file, idx) => ({
          ...(isFull ? file : {}),
          filePath: file.filePath,
          id: idx,
        }));

        resolve(data);
      })
      .catch(parseErrorResponseUpload)
      .then((err) => {
        getUploadVzonexS3AccessToken(true);
        reject(err);
      });
  });

const uploadFile = (data, isFull = false) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "users");
    }

    uploadFilesVzonexS3(data.body, isFull)
      .then((files) => {
        resolve(files[0]);
      })
      .catch(parseErrorResponseUpload)
      .then(reject);
  });

const uploadMultiFile = (data) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "unknown");
    }
    uploadFilesVzonexS3(data.body)
      .then((files) => {
        resolve(files);
      })
      .catch(parseErrorResponseUpload)
      .then(reject);
  });

const Service = {
  getRelations,
  getProfileById,
  getLocations,
  getDepartmentByUserId,
  createDepartment,
  updateDepartment,
  getFriends,
  addMemberToDepartment,
  getMemberInDepartment,
  removeMemberToDepartment,
  getUploadVzonexS3AccessToken,
  uploadFile,
  uploadMultiFile,
};

export default Service;
