/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { getUrlImage } from "utils/common";

const View = (props: any) => {
  const {
    index,
    typeUpload,
    id,
    idGlobal,
    sectionName,
    file,
    setFile,
    title,
    src,
  } = props;
  const fileRef = useRef<any>();
  const imgRef = useRef<any>();
  const [errUpload, setErrorUpload] = useState<boolean>(false);
  const [srcVideo, setSrcVideo] = useState("");

  const onUpload = async () => {
    const [f] = fileRef.current.files;
    setFile(f, id, sectionName, index, idGlobal);

    imgRef.current.src = URL?.createObjectURL(f);

    try {
      const formData = new FormData();
      formData.append("files", f);
      // const res: any = await APIs.UPLOAD.uploadFile({
      //   body: formData
      // })
      // if(res){
      //   setErrorUpload(false)
      //   setDataByKey("proof", res.filePath)
      // } else {
      //   setErrorUpload(true)
      // }
    } catch (error) {
      // console.log(error);
      setErrorUpload(true);
    }
  };

  useEffect(() => {
    if (src) {
      imgRef.current.src = getUrlImage(src);
    }
  }, [src]);


  return (
    <>
      <label htmlFor={id} className="upload-file-custom">
        {!Boolean(file) ? (
          <div hidden={src}>
            + {("Upload")} {title}
          </div>
        ) : (
          ""
        )}
        {srcVideo && (
          <video src={srcVideo} width="auto" height="100%" autoPlay loop>
            Your browser does not support HTML video.
          </video>
        )}

        {typeUpload === "application/pdf" && file ? (
          file.name
        ) : src ? (
          <img ref={imgRef} alt={id} />
        ) : (
          <img hidden={!Boolean(file)} ref={imgRef} alt={id} />
        )}
      </label>
      <input
        hidden={true}
        id={id}
        ref={fileRef}
        type="file"
        className="custom-file-input"
        accept={`${typeUpload ? `${typeUpload}/*` : "video/*,image/*"}`}
        required
        onChange={onUpload}
      />
    </>
  );
};

export default View;
