/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import Service from "services/api";
import { Button, Divider } from "semantic-ui-react";
import Members from "./Members";
import { getStringLimit } from "utils";

const node: any = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

const PreviewDeparment = () => {
  const defaultTree = [
    {
      title: "Deparment",
      children: [],
      _id: "",
    },
  ];
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const [profile, setProfile] = useState(null);
  const [treeData, setTreeData] = useState(defaultTree);
  const navigate = useNavigate();
  const [nodeSelected, setNodeSelected] = useState<any>(null);
  const [infoData, setInfoData] = useState({
    color: null,
  });
  const memberRef = useRef(null);

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  useEffect(() => {
    if (profile) getDepartment();
  }, [profile]);

  const convertDataResponse = (dataResponse) => {
    const getItems = (mapList, parentId) => {
      let items = mapList.filter((e: any) => e.parent_id === parentId);
      items.forEach((el: any) => {
        const items = getItems(mapList, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      return items;
    };
    if (dataResponse && dataResponse.length > 0) {
      let tree = dataResponse.filter(
        (e: any) => !e.parent_id || e?.parent_id == null
      );

      tree.forEach((el: any) => {
        const items = getItems(dataResponse, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      setTreeData(tree);
    }
  };

  const getData = async () => {
    try {
      const res: any = await Service.getProfileById(userId as string);
      if (res && res.user) {
        const _profile = {
          ...res.user,
          ...res.user.profile,
        };
        setProfile(_profile);
      }
    } catch (error) {}
  };

  const getDepartment = async () => {
    const _res: any = await Service.getDepartmentByUserId({
      params: {
        userId,
      },
    });
    if (_res && _res.value) {
      if (_res.value.length > 0) {
        const _id = uuidv4();

        const listItemChild = _res.value?.filter((el) => el.parent_id);
        let itemNotParent = _res.value?.filter((el) => !el.parent_id);
        
        itemNotParent.forEach((el: any) => {
          el.parent_id = _id;
        });
        console.log("itemNotParent", itemNotParent);

        const nodes = [
          {
            title: profile.full_name,
            _id: _id,
            parent_id: "",
          },
          ...itemNotParent,
          ...listItemChild
        ];
        // setTreeData((prev) => nodes);
        convertDataResponse(nodes);
      } else setTreeData([]);
      if (_res.info) setInfoData(_res.info);
    } else {
      setTreeData([]);
    }
  };

  const handleBack = async () => {
    navigate(`/department?userId=${userId}&token=${token}`);
  };

  const NodeLabel = (props) => {
    const { className, nodeData }: any = props;
    return (
      <div className={className}>
        <div
          className="title"
          style={{
            color: !nodeData?.parent_id
              ? infoData?.color?.parent
              : infoData?.color?.child,
          }}
        >
          {getStringLimit(nodeData.title, 24)}
        </div>
        <Divider></Divider>
        <Button
          size="mini"
          basic
          color="blue"
          circular
          icon="view"
          className="view-member"
          onClick={(_) => {
            setNodeSelected(nodeData);
          }}
        >
          View member
        </Button>
      </div>
    );
  };

  return (
    <div className="preview">
      {nodeSelected && (
        <Members
          ref={memberRef}
          node={nodeSelected}
          setNodeSelected={setNodeSelected}
        />
      )}
      <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
        {token && (
          <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
            <Divider></Divider>
            <Button
              size="mini"
              color="blue"
              disabled={!userId ? true : false}
              onClick={handleBack}
            >
              Back
            </Button>
            <Divider></Divider>
          </div>
        )}

        <Tree
          data={treeData}
          // nodeSvgShape={svgSquare}
          nodeSvgShape={node}
          pathFunc="step"
          separation={{ siblings: 1.4, nonSiblings: 1 }}
          orientation="vertical"
          translate={{ x: 400, y: 100 }}
          allowForeignObjects={true}
          nodeLabelComponent={{
            render: <NodeLabel className="node" />,
            foreignObjectWrapper: {
              width: 220,
              height: 200,
              y: -50,
              x: -100,
            },
          }}
          initialDepth={0.02}
        />
      </div>
    </div>
  );
};

export default PreviewDeparment;
