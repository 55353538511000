/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SortableTree, {
  TreeItem,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Label,
  Modal,
} from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Service from "services/api";
import { v4 as uuidv4 } from "uuid";
import { getUrlImage } from "utils";
import "./styles.scss";
import Members from "./Members";
import UploadFile from "components/uploadFile";

const View = () => {
  const defaultData = [
    {
      _id: uuidv4(),
      title: "Department 1",
    },
    {
      _id: uuidv4(),
      title: "Deparment 2",
    },
  ];

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [state, setState] = useState({
    searchString: "",
    searchFocusIndex: 0,
    currentNode: {},
  });
  const [treeDataResponse, setTreeDataResponse] = useState([]);

  const [treeData, setTreeData] = useState<TreeItem[]>(defaultData);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [nodeSelected, setNodeSelected] = useState(null);
  const [friends, setFriends] = useState([]);
  const [memberId, setMemberId] = useState(null);
  const [loadingAddMember, setLoadingAddMember] = useState(false);
  const [infoData, setInfoData] = useState({
    color: null,
  });
  const [listFile, setListFile] = React.useState({});

  const memberRef = useRef(null);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token_auth", token);
    }
  }, [token]);

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  useEffect(() => {
    convertDataResponse(treeDataResponse);
  }, [treeDataResponse]);

  const getData = async () => {
    try {
      const res: any = await Service.getDepartmentByUserId({
        params: {
          userId,
        },
      });
      if (res && res.value) {
        setIsEdit(true);
        if (res.value.length > 0) setTreeDataResponse(res.value);
        else setTreeData(defaultData);
        if (res.info) setInfoData(res.info);
      } else {
        setIsEdit(false);
      }
    } catch (error) {
      setIsEdit(false);
      setTreeData(defaultData);
    }
  };

  const expandAndCollapse = (expanded) => {
    setTreeData((prev) =>
      toggleExpandedForAll({
        treeData: prev,
        expanded,
      })
    );
  };

  const updateTreeData = (treeData) => {
    setTreeData(treeData);
  };

  const removeNode = (path) => {
    setTreeData(
      removeNodeAtPath({
        treeData: treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      })
    );
  };

  const selectThis = (node, path) => {
    setState((prev) => ({ ...prev, currentNode: node, path: path }));
  };

  const insertNewNode = () => {
    setTreeData(
      insertNode({
        treeData: treeData,
        depth: 0,
        minimumTreeIndex: treeData.length,
        newNode: { _id: uuidv4(), title: "", children: [] },
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const convertDataResponse = (dataResponse) => {
    const getItems = (mapList, parentId) => {
      let items = mapList.filter((e: any) => e.parent_id === parentId);
      items.forEach((el: any) => {
        const items = getItems(mapList, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      return items;
    };

    if (dataResponse && dataResponse.length > 0) {
      let tree = dataResponse.filter(
        (e: any) => !e.parent_id || e?.parent_id == null
      );

      tree.forEach((el: any) => {
        const items = getItems(dataResponse, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      setTreeData(tree);
    }
  };

  const convertDataPayload = (treeDataEdit) => {
    let data_payload = [];
    const convertChild = (data, parent_id) => {
      data_payload = (data_payload || []).concat({
        ...data,
        parent_id: parent_id,
        children: [],
      });
      if (data?.children?.length > 0) {
        for (let index = 0; index < data?.children?.length; index++) {
          const element = data?.children?.[index];
          convertChild(element, data._id);
        }
      }
    };

    treeDataEdit.forEach((el) => convertChild(el, ""));

    return data_payload;
  };

  const handleSubmit = async () => {
    try {
      let data = convertDataPayload(treeData);

      if (userId) {
        setLoading(true);

        let listImg;
        if (Object.keys(listFile).length > 0) {
          const formData = new FormData();
          formData.append("folderPath", `users/${userId}`);

          Object.keys(listFile).map((el: any) => {
            formData.append("files", listFile[el]);
          });
          listImg = await Service.uploadMultiFile({
            body: formData,
          });

          if (listImg && listImg?.length > 0) {
            listImg?.map((el: any) => {
              Object.keys(listFile || {}).map((item: any, indexKey: number) => {
                if (el.id === indexKey) {
                  for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if (String(element._id) === String(item)) {
                      data[index] = {
                        ...data[index],
                        icon: el?.filePath,
                      };
                    }
                    if (element.children?.length > 0) {
                      data[index] = {
                        ...data[index],
                        children: data[index]?.children.map((chil) =>
                          String(chil.id) === String(item)
                            ? {
                                ...chil,
                                icon: el?.filePath,
                              }
                            : chil
                        ),
                      };
                    }
                  }
                }
              });
            });
          }
        }

        if (isEdit) {
          await Service.updateDepartment({
            body: {
              user_id: userId,
              value: data,
              info: infoData,
            },
          });
        } else {
          const res = await Service.createDepartment({
            body: {
              user_id: userId,
              value: data,
              info: infoData,
            },
          });
          if (res) getData();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePreview = async () => {
    navigate(`/preview?userId=${userId}&token=${token}`);
  };

  const searchModal = async (e) => {
    try {
      const res: any = await Service.getFriends({
        query: {
          search: e.target.value,
          limit: 10,
          page: 1,
          userId: userId,
        },
      });
      if (res && res.docs) {
        setFriends(res.docs);
      }
    } catch (error) {
      setFriends([]);
    }
  };

  const setFile = (f: any, id: string) => {
    setListFile((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleAddUser = async () => {
    try {
      if (memberId && nodeSelected) {
        setLoadingAddMember(true);
        await Service.addMemberToDepartment({
          body: {
            member_id: memberId,
            department_id: nodeSelected._id,
            user_id: userId,
          },
        });
        memberRef.current.reload();
        setLoadingAddMember(false);
        setOpen(false);
      }
    } catch (error) {
      setLoadingAddMember(false);
    }
  };

  const handleOnChangeInfo = (name, value) => {
    console.log(name, value);
    setInfoData((prev) => ({
      ...prev,
      color: {
        ...prev.color,
        [name]: value,
      },
    }));
  };

  const onUpload = async (file, id) => {
    setOpenUpload(false);
  };

  const ModalAddUser = (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Add User</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Select Friend"
          onSearchChange={searchModal}
          fluid
          search
          selection
          onChange={(_, { value }) => {
            setMemberId(value);
          }}
          options={friends.map((f) => ({
            key: f._id,
            text: `${f.full_name} (${f.refer_code})`,
            value: f._id,
            image: { avatar: true, src: getUrlImage(f.avatar) },
          }))}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
            setMemberId("");
          }}
        >
          Cancel
        </Button>
        <Button
          content="Confirm"
          labelPosition="right"
          icon="checkmark"
          loading={loadingAddMember}
          onClick={() => handleAddUser()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );

  const ModalUpload = (
    <Modal
      onClose={() => setOpenUpload(false)}
      onOpen={() => setOpenUpload(true)}
      open={openUpload}
      style={{
        maxWidth: "400px",
        width: "100%",
      }}
    >
      <Modal.Header>Add Icon</Modal.Header>
      <Modal.Content>
        <UploadFile
          id={nodeSelected?._id}
          file={listFile?.[nodeSelected?._id]}
          src={nodeSelected?.icon}
          setFile={setFile}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpenUpload(false);
            setMemberId("");
          }}
        >
          Cancel
        </Button>
        <Button
          content="Confirm"
          labelPosition="right"
          icon="checkmark"
          loading={loadingAddMember}
          onClick={() =>
            onUpload(listFile?.[nodeSelected?._id], nodeSelected?._id)
          }
          positive
        />
      </Modal.Actions>
    </Modal>
  );

  return (
    <div style={{ height: 800 }} className="">
      {ModalAddUser}
      {ModalUpload}
      {nodeSelected && (
        <Members
          ref={memberRef}
          node={nodeSelected}
          setNodeSelected={setNodeSelected}
          isAuth={token ? true : false}
        />
      )}
      <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
        <Divider></Divider>
        <Button
          size="mini"
          color="blue"
          disabled={!userId ? true : false}
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          size="mini"
          color="blue"
          disabled={!userId ? true : false}
          onClick={handlePreview}
        >
          Preview
        </Button>
        <Button
          size="mini"
          color="blue"
          onClick={() => expandAndCollapse(true)}
        >
          Expand all
        </Button>
        <Button
          size="mini"
          color="blue"
          onClick={() => expandAndCollapse(false)}
        >
          Collapse all
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Input
          size="mini"
          placeholder="Search"
          value={state.searchString}
          onChange={(event) =>
            setState((prev) => ({ ...prev, searchString: event.target.value }))
          }
        />
      </div>
      <div>
        <div className="setting-color-friends-folder">
          <Label>{"Color Level 1"}</Label>
          <div className="setting-color-item">
            <Input
              id="colorfield"
              name="color"
              type="color"
              value={infoData?.color?.parent}
              onChange={(e) => handleOnChangeInfo("parent", e.target.value)}
            />
          </div>
        </div>
        <div className="setting-color-friends-folder">
          <Label>{"Color Level 2"}</Label>
          <div className="setting-color-item">
            <Input
              id="colorfield"
              name="color"
              type="color"
              value={infoData?.color?.child}
              onChange={(e) => handleOnChangeInfo("child", e.target.value)}
            />
          </div>
        </div>
      </div>
      <Divider></Divider>
      <SortableTree
        searchQuery={state.searchString}
        treeData={treeData}
        searchFocusOffset={state.searchFocusIndex}
        onChange={updateTreeData}
        generateNodeProps={({ node, path }) => ({
          title: (
            <form
              className="form-sort"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectThis(node, path);
              }}
            >
              <input
                style={{
                  fontSize: "1rem",
                  width: 200,
                  color: treeData?.find((item) => item._id === node._id)
                    ? infoData?.color?.parent
                    : infoData?.color?.child,
                }}
                value={node.title}
                onChange={(event) => {
                  const title = event.target.value;
                  setTreeData((prev) =>
                    changeNodeAtPath({
                      treeData: prev,
                      path,
                      getNodeKey,
                      newNode: { ...node, title },
                    })
                  );
                }}
              />
              &nbsp;&nbsp;&nbsp;
              {node?.icon || listFile?.[node?._id] ? (
                <div className="icon-item">
                  <img
                    width={36}
                    height={36}
                    src={
                      listFile?.[node?._id]
                        ? window.URL.createObjectURL(listFile?.[node?._id])
                        : getUrlImage(node?.icon)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setNodeSelected(node);
                      setOpenUpload(true);
                    }}
                    alt=""
                  />
                </div>
              ) : (
                <Button
                  size="mini"
                  basic
                  color="twitter"
                  circular
                  icon="upload"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setNodeSelected(node);
                    setOpenUpload(true);
                  }}
                />
              )}
              <Button
                size="mini"
                basic
                color="blue"
                circular
                icon="add"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  insertNewNode();
                }}
              />
              <Button
                size="mini"
                basic
                color="red"
                circular
                icon="trash"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeNode(path);
                }}
              />
              <Button
                size="mini"
                basic
                color="green"
                circular
                icon="user"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNodeSelected(node);
                  setOpen(true);
                }}
              />
              <Button
                size="mini"
                basic
                color="twitter"
                circular
                icon="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNodeSelected(node);
                }}
              />
            </form>
          ),
        })}
      />
    </div>
  );
};

export default View;
