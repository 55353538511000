export const getUrlImage = (img, rezise?: number) => {
  if (!img) return "/assets/images/VDB-64.png";

  const typeFile = img.split(".")[img.split(".").length - 1];
  const checkFileImage = () => {
    const permission_type = [
      "jpg",
      "jpeg",
      "gif",
      "png",
      "tiff",
      "bmp",
      "svg",
      "psd",
      "ai",
      "webp",
    ];
    if (permission_type.includes(typeFile.toLowerCase())) {
      return true;
    }
    return false;
  };

  let newImg = img.replace("uploads/", "/");

  if (checkFileImage()) {
    newImg = newImg.replace(
      `.${typeFile}`,
      `--${rezise && rezise < 900 ? rezise : 900}.${typeFile}`
    );
  }

  if (
    String(newImg).includes("http://") ||
    String(newImg).includes("https://")
  ) {
    return newImg;
  }
  return `${
    process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
  }/api/files/${newImg}`;
};
